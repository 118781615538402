
import {React} from "react"
import { useState, useEffect, useContext} from "react"
import { Card, Button } from "react-bootstrap";



function Project(props) {

    
    const [isHovered, setIsHovered] = useState(false);
    const [isSkillHovered, setIsSkillHovered] = useState(100);
    const [isTagHovered, setIsTagHovered] = useState(100);
    const [tagFilter, setTagFilter] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [skillLog, setSkillLog] = useState([]);
  
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
          }, 20);
          props.changeFilterStatus(skillLog)
    }, [skillLog]);



    const pressFilter = (skill) => {
        setSkillLog(prevItems => [...prevItems, skill]);
    }

    const pressTag = (project, tag) => {
        if (project.url !== null) {
            if (tag === 'website') {
                return project.projectUrl;
            } else {
                return project.url;
            }
        }
        return ""
    }



return (
        <Card
            style={{opacity: loaded ? 1 : 0, transition: 'opacity ' + props.project.index * 0.5 + 's ease', margin: "2rem", padding: "0.5rem", height: "100%", backgroundColor: isHovered ? "#f0f0f0" : "transparent", alignItems: "center", font: "droid sans"}} 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
            <Card.Body>

                <a href={props.project.url} style={{textDecoration: "none", color: "black"}} target="_blank">
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center"}}>
                            <p style={{ font: "droid sans", display: "inline", color: "red", fontWeight: "bold", margin: 0, fontSize: 22}}>#</p>
                            <p style={{ display: "inline", font: "droid sans", fontWeight: "bold", margin: 0, paddingLeft: "5px", fontSize: 18}}>{props.project.title}</p>
                        </div>
                    </div>
                </a>
                <div >
                    {props.project.skills && props.project.skills.map((skill, index) => (
                        <span key={index} onMouseEnter={() => setIsSkillHovered(index)} 
                            onMouseLeave={() => setIsSkillHovered(100)} 
                            style={{color: "#424242", fontSize: 12, borderRadius: "5px", margin: "0.4rem" , paddingLeft: "0.4rem", paddingRight: "0.4rem", marginRight: "1%", paddingTop: "0.2rem", paddingBottom: "0.2rem", display: "inline-block", backgroundColor: (isSkillHovered === index) ? "#b5b5b5": "#c7c7c7", alignItems: "center", margin: 0 }}
                            > 
                            {skill} 
                        </span>
                    ))}
                </div>
                <a href={props.project.url} style={{textDecoration: "none", color: "black"}} target="_blank">
                    <p style={{ paddingTop: "1.5%" }}>{props.project.description}</p>
                    <div>
                        {props.project.tags && props.project.tags.map((tag, index) => (
                            <span key={index} style={{ display: "inline-block", color: "red", margin: 0, fontSize: 14 }}>
                                <a href={pressTag(props.project, tag)} target="_blank" style={{font: "droid sans", display: "inline-block", color: (isTagHovered !== index) ? "red" : "#b80000", textDecoration: "none"}} onMouseEnter={() => setIsTagHovered(index)} onMouseLeave={() => setIsTagHovered(100)}>   
                                    {tag}
                                </a> 
                            <span style={{ margin: "0.4rem" }}></span></span>
                        ))}
                    </div>
                </a>
            </Card.Body>
        </Card>
        
    );
}

export default Project;
