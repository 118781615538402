import { useCallback, useEffect, useState } from "react"
import { Card, Button } from "react-bootstrap"

import Typewriter from 'typewriter-effect';


export default function EconomicMLPaper(props) {



  return (
    <div className="App">
        <div style={{paddingLeft: "24.25%", paddingRight: "18.5%", paddingTop: "0.25%", font: "droid sans", alignItems: "center", lineHeight: "150%"}} className="Proj-Description">
            <hr />

            <h1 style={{paddingTop: "0.5%"}}>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter.typeString("Economic ML Paper").start()
                    }}
                />
            </h1>
            <h4 style={{paddingTop: "0.5%", color: "gray"}}></h4>
            <p style={{paddingTop: "2.5%"}}>
                In this paper I will be using Machine Learning to analyze the trends between multiple macroeconomic indicators compared to the free cash value of large cap companys in the S{"&"}P 500.
            </p>
            <p style={{paddingTop: "2.5%"}}>
                I am in the process of publishing this article.
            </p>
        </div>
    </div>
  );
}

