import { useCallback, useEffect, useState } from "react"
import Project from './Project'
import Skill from './Skill'
import { Card, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
// import '../App.css';

export default function Projects(props) {
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filtersActivated, setFiltersActivated] = useState([]);

    useEffect(() => {
        setProjects(prevList => [
            {
                index: 0,
                title: "TradeSim",
                description: "A dynamic, full-stack web application that enables users to simulate stock market investments with virtual currency. Hosted on an AWS EC2 instance, the platform features a React and TypeScript frontend and a Spring Boot backend. Leveraging a MongoDB cluster, it ensures efficient data management and supports seamless user account creation via OAuth integration.",
                tags: ["code", "website"],
                skills: ["React", "Spring Boot", "AWS", "MongoDB"],
                url: "https://github.com/adityakmehrotra/TradeSim",
                projectUrl: "https://tradesim.adityakmehrotra.com" },
            { 
                index: 1,
                title: "LeafAI", 
                description: "A cloud-based web application hosted on an AWS EC2 instance that uses a Sequential machine learning model with Tensor Flow to identify leaf species from uploaded images with an approximate validation accuracy of 86%. Developed the web application using React and TypeScript for the frontend and Flask for the backend. Employed a MongoDB cluster to efficiently handle data management tasks, and used AWS S3 to securely store and retrieve image files.", 
                tags: ["code", "website"],
                skills: ["React", "Flask", "Tensor Flow", "AWS", "MongoDB"], 
                url: "https://github.com/adityakmehrotra/LeafAI", 
                projectUrl: "https://leafai.adityakmehrotra.com" },
            { 
                index: 2,
                title: "Wordle Solver", 
                description: "A Python algorithm that optimizes Wordle guesses by analyzing letter frequencies and positions in five-letter words. I achieved an average of 3.67 guesses per game through implementing a recursive strategy to systematically refine potential solutions after each guess, significantly enhancing the algorithm's efficiency.", 
                tags: ["code"],
                skills: ["Python", "AWS"], 
                url: "https://github.com/adityakmehrotra/Wordle-Solver" },
            { 
                index: 3,
                title: "Monopoly", 
                description: "A cloud-based Monopoly web application hosted on an AWS EC2 instance, developed with React and TypeScript for an interactive and responsive front-end. The backend is engineered with Django and incorporates comprehensive gameplay mechanics, property management, and multiplayer capabilities, providing a robust and engaging experience for users.", 
                skills: ["React", "Django", "AWS"], },
            { 
                index: 4,
                title: "Analyzing the Relationship between a Chocolate's Cacao Percentage and its Rating", 
                description: "A Linear Regression model was utilized to analyze and determine the relationship between the cocoa percentage of a type of chocolate and its objective rating. The analysis was based on a dataset of 2,600 ratings of plain (non-flavored) chocolate bars produced by chocolate manufacturers worldwide.", 
                tags: ["code"], 
                skills: ["R", "Data Analysis"],
                url: "https://github.com/adityakmehrotra/Cocoa-Percentage-vs-Rating" },
            { 
                index: 5,
                title: "Minesweeper", 
                description: "A fully functional Minesweeper game was developed using Haskell, designed to be played directly through the Command Line Interface. This implementation takes advantage of Haskell's functional programming capabilities to ensure efficient and responsive game dynamics.", 
                tags: ["code"], 
                skills: ["Haskell", "Bash"],
                url: "https://github.com/adityakmehrotra/Minesweeper" },
        ]);

        setTimeout(() => {
            setLoaded(true);
        }, 500);
    }, [filtersActivated]);

    function changeFilterStatus(skill) {
        setFiltersActivated(skill);
    };

    // 

    return (
        <div>
            {
                (filtersActivated.length > 0) ? 
                    <div style={{paddingLeft: "23%", paddingRight: "17.5%", paddingTop: "0.25%", font: "droid sans"}}>
                        <div style = {{padding: "0.5rem", height: "100%"}}>
                            <Skill skills={filtersActivated}></Skill>
                        </div>
                    </div>
                : null
            }
            <div className="App" style={{paddingLeft: "22.5%", paddingRight: "17.5%", paddingTop: "0.25%", font: "droid sans"}}>
                {projects?.map((project, index) => (
                    <Project key={index} project={project} changeFilterStatus={changeFilterStatus}></Project>
                ))}
            </div>
        </div>
    );
}

