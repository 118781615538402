import './App.css'
import Navigator from './components/Navigator'

function App() {
  return <Navigator/>
}

export default App


