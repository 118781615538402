import { useCallback, useEffect, useState } from "react"
import { Card, Button, Image } from "react-bootstrap"

import uwcuHubLogo from "../../../logo/uwcu_logo.png";


export default function Uwcu(props) {



  return (
    <div className="App">
        <div style={{paddingLeft: "25%", paddingRight: "19.5%", paddingTop: "0.5%", alignItems: "center", lineHeight: "150%"}} className="Proj-Description">
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                <h1 style={{display: "inline", margin: 0, paddingTop: "0.5%"}}>UW Credit Union</h1>
            </div>
            <a href="https://www.uwcu.org/" target="_blank" style={{textDecoration: "none"}}>
              <Image src={uwcuHubLogo} alt="UW Credit Union Logo" style={{width: '25%', float: "right", height: '25%', marginTop: "-2.55rem", marginRight: "0.5rem" }}></Image>
            </a>
            <h4 style={{paddingTop: "0.5%", color: "gray"}}>IT Intern</h4>
            <p style={{ paddingTop: "5%", display: "inline", margin: 0, fontSize: 16, color: "gray"}}>May 2023 - Aug 2023</p>
            <p style={{paddingTop: "2.5%"}}>
              During my internship, I had the opportunity to contribute significantly to the IT department. 
              My responsibilities were diverse and centered around enhancing our technological infrastructure and streamlining processes. I played a key role in imaging new computers, ensuring each device was equipped and optimized for performance right from the start. 
            </p>
            <p style={{paddingTop: "2.5%"}}>
              Beyond hardware, I leveraged my programming skills to write Python scripts that improved the computer imaging process, making it faster and more reliable. This internship was a profound learning experience that enhanced my practical IT skills and my ability to contribute effectively to a large organization.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              Additionally, I was involved in hardware upgrades, specifically installing new RAM in computers for custom builds tailored to specific departmental needs. 
              Addressing employer ticketing issues was another critical part of my role, where I provided timely solutions to IT-related queries and problems, enhancing overall workplace efficiency.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              This hands-on experience not only deepened my technical skills but also gave me insights into customizing solutions based on varied user needs. I also assisted in setting up a new office space, which involved assembling desktop setups and meticulously connecting them to the network via ethernet. 
            </p>
        </div>
    </div>
  );
}

