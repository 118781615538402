import { useCallback, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';


function Coursework() {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
          }, 20);
    }, []);

    const pressButton = (className, type) => {
        navigate(`/coursework/${className}/${type}`);
    }


  return (
    <div className="App" style={{font: "droid sans", paddingLeft: "25%", paddingRight: "18.5%", paddingTop: "2.5%", lineHeight: "150%"}}>
        <div style={{paddingTop: "0.25%", lineHeight: "150%"}} className="Proj-Description">
            <p style={{opacity: loaded ? 1 : 0, transition: 'opacity 0.5s ease', paddingTop: "0.25%", font: "droid sans"}}>
                This is the list of some of the classes that I have taken, or am currently taking, as an undergraduate student at the University of Wisconsin-Madison. I have included links to some of the projects and notes that I have for that class.
            </p>

        <div style={{opacity: loaded ? 1 : 0, transition: 'opacity 1.5s ease', paddingTop: "2.5%", paddingBottom: "0.1%"}}>
            <p>
                Computer Science
            </p>
            <ul style={{paddingTop: "0.1%", font: "droid sans", }}>
                <li>
                    Comp Sci 537: Operating Systems
                </li>
                <li>
                    Comp Sci 577: Algorithms (<a href="" onClick={() => pressButton("CS577", "project")} style={{textDecoration: "none", color: "#0fa838"}}>project</a>)
                </li>
                <li>
                    Comp Sci 354: Distributed Systems (<a href="https://github.com/adityakmehrotra/Memory-Allocator/tree/main" target="_blank" style={{textDecoration: "none", color: "#0fa838"}}>project</a>)
                </li>
                <li>
                    Comp Sci 564: Database Management Systems
                </li>
                <li>
                    Comp Sci 571: User Interfaces
                </li>
                <li>
                    Comp Sci 400: Programming III (<a href="" onClick={() => pressButton("CS400", "project")} style={{textDecoration: "none", color: "#0fa838"}}>project</a>)
                </li>
                <li>
                    Comp Sci 320: Data Science Programming II (<a href="" onClick={() => pressButton("CS320", "notes")} style={{textDecoration: "none", color: "#0fa838"}}>notes</a>) (<a href="" onClick={() => pressButton("CS320", "project")} style={{textDecoration: "none", color: "#0fa838"}}>project</a>)
                </li>
                <li>
                    Comp Sci 300: Programming II (<a href="" onClick={() => pressButton("CS300", "notes")} style={{textDecoration: "none", color: "#0fa838"}}>notes</a>) (<a href="" onClick={() => pressButton("CS300", "project")} style={{textDecoration: "none", color: "#0fa838"}}>project</a>)
                </li>
            </ul>
        </div>

        <div style={{opacity: loaded ? 1 : 0, transition: 'opacity 2.5s ease'}}>
            <p style={{paddingTop: "1.5%"}}>
                Data Science
            </p>
            <ul style={{paddingTop: "0.1%"}}>
                <li>
                    Stat 340: Data Science Modelling II (<a href="" onClick={() => pressButton("STAT340", "notes")} style={{textDecoration: "none", color: "#0fa838"}}>notes</a>)
                </li>
                <li>
                    Comp Sci 320: Data Science Programming II (<a href="" onClick={() => pressButton("CS320", "notes")} style={{textDecoration: "none", color: "#0fa838"}}>notes</a>) (<a href="" onClick={() => pressButton("CS320", "project")} style={{textDecoration: "none", color: "#0fa838"}}>project</a>)
                </li>
                <li>
                    Stat 309: Introduction to Probability and Mathematical Statistics I
                </li>
                <li>
                    Stat 240: Data Science Modelling I (<a href="https://github.com/adityakmehrotra/Analyzing-Exoplanets" target="_blank" style={{textDecoration: "none", color: "#0fa838"}}>notes</a>) (<a href="https://github.com/adityakmehrotra/Cacao-Percentage-vs-Rating" style={{textDecoration: "none", color: "#0fa838"}} target="_blank" >project</a>)
                </li>
                <li>
                    LIS 461: Data and Algorithms: Ethics and Policy
                </li>
            </ul>
        </div>    

        <div style={{opacity: loaded ? 1 : 0, transition: 'opacity 3.5s ease'}}>
            <p style={{paddingTop: "1.5%"}}>
                Economics
            </p>
            <ul style={{paddingTop: "0.1%"}}>
                <li>
                    Econ 410: Introductory Econometrics
                </li>
                <li>
                    Econ 310: Statistical Measurement in Technology (<a href="https://drive.google.com/drive/folders/16bGYCmzmvWcqVadCzY7Obm-G_qnwjrQh?usp=drive_link" target="_blank" style={{textDecoration: "none", color: "#0fa838"}}>essays</a>)
                </li>
                <li>
                    Econ 302: Intermediate Macroeconomic Theory
                </li>
                <li>
                    Econ 301: Intermediate Microeconomic Theory
                </li>
                <li>
                    Econ 111: Principles of Microeconomics and Macroeconomics
                </li>
            </ul>
        </div>

        <div style={{opacity: loaded ? 1 : 0, transition: 'opacity 4.5s ease'}}>
            <p style={{paddingTop: "1.5%"}}>
                Mathematics
            </p>
            <ul style={{paddingTop: "0.1%"}}>
                <li>
                    Comp Sci/Math 240: Discrete Mathematics
                </li>
                <li>
                    Math 340: Linear Algebra
                </li>
                <li>
                    Math 234: Calculus 3
                </li>
            </ul>
        </div>
        </div>
        </div>
  );
}


export default Coursework;
