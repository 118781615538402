import {React} from "react"
import { useState, useEffect, useContext} from "react"
import { Card, Button } from "react-bootstrap";



function Skill(props) {

    
    const [isHovered, setIsHovered] = useState(false);
    const [isSkillHovered, setIsSkillHovered] = useState(100);
    const [isTagHovered, setIsTagHovered] = useState(false);
    const [tagFilter, setTagFilter] = useState([]);
    const [loaded, setLoaded] = useState(false);
  
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
          }, 20);
    }, []);





return (
        <Card style={{border: "none"}}>
            <Card.Body style={{borderColor: "white"}}>
                <div>
                    <p style={{fontSize: 20, fontWeight: 500, color: "#1f1f1f"}}>Filters</p>
                    {props.skills && props.skills.map((skill, index) => (
                        <span key={index} onMouseEnter={() => setIsSkillHovered(index)} 
                            onMouseLeave={() => setIsSkillHovered(100)} 
                            style={{color: "#424242", fontSize: 12, borderRadius: "5px", margin: "0.4rem" , paddingLeft: "0.4rem", paddingRight: "0.4rem", marginRight: "1%", paddingTop: "0.2rem", paddingBottom: "0.2rem", display: "inline-block", backgroundColor: (isSkillHovered === index) ? "#b5b5b5": "#c7c7c7", alignItems: "center", margin: 0 }}
                            
                            > 
                            {skill} 
                        </span>
                    ))}
                </div>
            </Card.Body>
        </Card>
        
    );
}

export default Skill;